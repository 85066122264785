import React, { useEffect } from 'react';
import Pagination from '@mui/material/Pagination';
import { useSearchParams } from "react-router-dom";
import PropTypes from 'prop-types';
import { priceRangeFilterSelector, shoeTypeFilterSelector, sizesFilterSelector, brandsFilterSelector } from '../../../store/selectors';
import { useSelector } from 'react-redux';
import "./style.scss"

function PaginationProducts({ getProductsApi, totalPages, defaultSearchParamsPage, page, setPage }) {
  const priceRangeFilter = useSelector(priceRangeFilterSelector);
  const shoeTypeFilter = useSelector(shoeTypeFilterSelector);
  const sizesFilter = useSelector(sizesFilterSelector);
  const brandsFilter = useSelector(brandsFilterSelector);

  let [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    setPage(Number(defaultSearchParamsPage));
  },[defaultSearchParamsPage]);

  const handlePageChange = (e, page) => {
    const params = {};
    setSearchParamsPage(page);
    setPage(page);

    if (priceRangeFilter[0]) params.minPriceRange = priceRangeFilter[0];
    if (priceRangeFilter[1]) params.maxPriceRange = priceRangeFilter[1];
    if (shoeTypeFilter) params.shoeType = shoeTypeFilter;
    if (sizesFilter) params.size = sizesFilter;
    if (brandsFilter) params.brand = brandsFilter;

    getProductsApi(page, 12, params, true);
  }

  const setSearchParamsPage = (page) => {
    searchParams.set('page', page);
    setSearchParams(searchParams);
  }
  
  return (
    <Pagination
      count={totalPages}
      variant="outlined"
      shape="rounded"
      page={page}
      onChange={handlePageChange}
      defaultPage={1}
    />
  );
}

PaginationProducts.propTypes = {
  totalPages: PropTypes.number.isRequired,
  getProductsApi: PropTypes.func.isRequired,
};

export default PaginationProducts;