import React from "react";
import { Input, Label, Row, Col } from 'reactstrap';
import PropTypes from 'prop-types'

function SizeFilter ({ selectedSizes, sizes, setFilters, setPage }) {
    const onChangeSize = (e, size) => {
        const isChecked = e.target.checked;

        if(isChecked) {
            setPage(1);
            setFilters((prevFilters) => ({
                ...prevFilters,
                selectedSizes: [...selectedSizes, size]
            }));
        } else {
            setPage(1);
            setFilters((prevFilters) => ({
                ...prevFilters,
                selectedSizes: selectedSizes.filter(value => value !== size)
            }));
        }

    }

    return (
        <div className="product-filter">
            <h4>РАЗМЕРИ</h4>
            <Row>
                {sizes.map((size, i) => {
                    return <Col xs={6} key={`${i}-${size}`}  className="mb-1">
                                <Label className="filter-checkbox">
                                    <Input
                                        type="checkbox"
                                        value={size}
                                        checked={selectedSizes.includes(size)}
                                        onChange={(e) => onChangeSize(e, size)}
                                    />
                                    <span>{size}</span>
                                </Label>
                            </Col>
                })}
            </Row>
        </div>
    )
}

SizeFilter.propTypes = {
    selectedSizes: PropTypes.arrayOf(PropTypes.number).isRequired,
    sizes: PropTypes.arrayOf(PropTypes.number).isRequired,
    setFilters: PropTypes.func.isRequired
};

export default SizeFilter;
