import React from 'react';

import PropTypes from 'prop-types'
import { Link } from "react-router-dom";
import { Row } from "reactstrap";

import "./style.scss"

function ProductDetails({ product, articleNumber, isCompact }) {
  const categories = {
    ski: "Ски",
    shoe: "Обувки",
    snowboard: "Сноуборд дъски"
  };

  const {
      title,
      product_type,
      brand,
      size,
      price,
      discount_price,
      discount_percentages
  } = product;

  return (
    <div className={`product-details ${isCompact ? 'product-details-cart' : ''}`}>
        <h4>{title}</h4>
        <Row className="category-brand">
            <div xs={isCompact ? '4' : '12'}>
                <span>Категория: </span>
                <span>{categories[product_type]}</span>
            </div>
            <div xs={isCompact ? '4' : '12'}>
                <span>Производител: </span>
                <span>{brand}</span>
            </div>
            <div>
                <span>Размер: </span>
                <span>{size}</span>
            </div>
        </Row>
        <div className="product-details-id">
            <span>Артикулен номер №:</span>
            <Link to={`/product/${articleNumber}`}>
              <span className='article-number-details'>{articleNumber}</span>
            </Link>
        </div>
        <div className="product-price-details">
            {discount_price && <h2>{discount_price} лв.</h2>}
            {discount_percentages ? <span>{price} лв.</span> : <></>}
        </div>
    </div>
  );
}

ProductDetails.propTypes = {
  product: PropTypes.object.isRequired,
  articleNumber: PropTypes.string.isRequired,
  isCompact: PropTypes.bool
};

export default ProductDetails;