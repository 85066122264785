import React, { useState } from "react";
import { ReactComponent as ViberSvg } from '../../../../assets/images/viber.svg';
import PropTypes from 'prop-types';
import './style.scss'; 

function OrderButton ({ className, noHover }) {
    const [isClicked, setIsClicked] = useState(false);

    const onClick = () =>{ 
      setIsClicked(true);
    }

    return (
        <button 
            className={`default-btn order-btn ${isClicked ? 'btn-clicked' : ''} ${className ? className : ''} ${noHover ? 'no-hover' : ''}`}
            onClick={onClick}
        >
          {isClicked
            ? <a href="tel:+359883303396">0883303396 <ViberSvg/></a>
            : 'направи запитване'
          }
        </button>
    )
}

OrderButton.propTypes = {
  className: PropTypes.string,
  noHover: PropTypes.bool,
};

export default OrderButton;