import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import store from './store/store';
import { setCartProductsAction } from './store/actions';
import Router from './routes/router';
import "./styles/app.scss";

function App() {

  //App componentDidMount
  useEffect(() => {
    const cartProducts = JSON.parse(window.localStorage.getItem('cartProducts'));
    cartProducts instanceof Array && store.dispatch(setCartProductsAction(cartProducts));
  },[])

  return (
    <div className="app">
      <Provider store={store}>
        <BrowserRouter>
            <Router/>
        </BrowserRouter>
      </Provider>
    </div>
  );
}

export default App;
