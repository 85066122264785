import { path } from 'ramda';

export const isPreloaderLoadingSelector = path(['preloader']);
export const priceRangeFilterSelector = path(['priceRangeFilter']);
export const shoeTypeFilterSelector = path(['shoeTypeFilter']);
export const sizesFilterSelector = path(['sizesFilter']);
export const brandsFilterSelector = path(['brandsFilter']);
export const cartProductsSelector = path(['cartProducts']);
export const toastifySelector = path(['toastify']);

