import React from "react";
import { Row, Col } from "reactstrap";
import './style.scss'; 

export default function DiscountBar  () {
    return (
        <Row className="align-items-center discount-bar text-center align-center">
            <Col>
                <h3>До края на ноември 10% отстъпка върху общата сума</h3>
            </Col>
        </Row>
    )
}
