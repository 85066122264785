import store from "./store/store";
import { setCartProductsAction, setToastifyAction } from './store/actions';

export function addToCart(acticleNumber, title) {
    const currentCartProducts = JSON.parse(window.localStorage.getItem('cartProducts')) || [];
    let articleNumsLocalStorage = currentCartProducts;

    if (!currentCartProducts.includes(acticleNumber)) {
        articleNumsLocalStorage = JSON.stringify([...currentCartProducts, acticleNumber]);
        window.localStorage.setItem('cartProducts', articleNumsLocalStorage);
    }

    let parseNumbersToArrayForStore = articleNumsLocalStorage instanceof Array ? articleNumsLocalStorage : JSON.parse(articleNumsLocalStorage);
    
    store.dispatch(setToastifyAction(`Успешно добавихте ${title} към количката за пазаруване!`));
    store.dispatch(setCartProductsAction(parseNumbersToArrayForStore));
}