import React from "react";

import { Link } from "react-router-dom";
import PropTypes from 'prop-types';

import OrderButton from "../../Buttons/OrderButton";
import DiscountPrice from '../DiscountPrice';
import { addToCart } from '../../../../helpers';
import CartIcon from "../../../images/Icons/CartIcon/CartIcon";

import './style.scss'; 

function ProductItem ({product}) {
    const { article_number, images, price, discount_price, title, discount_percentages, size, product_type } = product;
    
    return (
        <div className="product-item box-shadow-hover">
            {discount_percentages
                ? <span className="discount-container">
                    -{discount_percentages}%
                </span>
                : <></>
            }
            <Link to={`/product/${article_number}`}>
                <div className="product-img">
                    <img src={images[0]?.url} alt="product img"/>
                </div>
                <div className="product-title">
                    <p>{title} 
                        {product_type !== "shoe" 
                            ? <> - <b>{size}см</b></>
                            : <></>
                        }
                    </p>
                </div>
            </Link>
            <div className="line-separator">
                <span/>
            </div>
            <DiscountPrice discountPrice={discount_price} price={price}/>
            <div className="product-double-btn">
                <OrderButton />
                <button 
                    className="default-btn base-btn cart-btn-icon"
                    onClick={()=> addToCart(article_number, title)}
                >
                    <CartIcon/>
                </button>
            </div>
        </div>
    )
}

ProductItem.propTypes = {
    product: PropTypes.shape({
        article_number: PropTypes.string.isRequired,
        images: PropTypes.arrayOf(PropTypes.object).isRequired,
        price: PropTypes.number.isRequired,
        discount_price: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
    }),
};

export default ProductItem;