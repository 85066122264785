import React from "react";

import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

import ProductDetails from "../../../common/ProductSections/ProductDetails";
import BaseButton from "../../../common/Buttons/BaseButton";
import store from '../../../../store/store';
import { setCartProductsAction } from '../../../../store/actions';

import './style.scss'; 

export default function CartItem ({ product, setProducts }) {
    const { article_number } = product;

    const removeCartItem = (article_number) => {
        const currentCartProducts = JSON.parse(window.localStorage.getItem('cartProducts'));
        const updatedCartProducts = currentCartProducts.filter(item => item !== article_number);
        const localStorageCartProducts = JSON.stringify(updatedCartProducts);
        
        window.localStorage.setItem('cartProducts', localStorageCartProducts);
        store.dispatch(setCartProductsAction(updatedCartProducts));
        setProducts((prevProducts) => prevProducts.filter((product) => product.article_number !== article_number));
    }

    return (
        <Container className="mt-4 cart-item">
            {Object.keys(product).length !== 0 && product.constructor === Object
                ? <Row>
                    <Col xs={12}>
                        <Row>
                            <Col xs={4} md={3}>
                                <Link to={`/product/${article_number}`}>
                                    <div className="product-img">
                                        <img src={product.images[0]?.url} alt="product img"/>
                                    </div>
                                </Link>
                            </Col>
                            <Col xs={8} md={6}>
                                <ProductDetails product={product} articleNumber={article_number} isCompact/>
                            </Col>
                            <Col className="d-flex align-items-center" xs={12} md={3}>
                                <BaseButton text="премахни" onClick={() => removeCartItem(article_number)}/>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                : <></>
            }
        </Container>
    )
}