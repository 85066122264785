import React from "react";
import slideOneBackgroundImage from "../../../../assets/images/slide-1.webp";
import BaseButton from "../../../common/Buttons/BaseButton/BaseButton";
import './style.scss'; 

export default function Hero() {
  return (
    <div className="hero-section" style={{ backgroundImage: `url(${slideOneBackgroundImage})`}}>
      <div className="hero-banner">
        <h1>Втора употреба <b>ски</b> и <b>сноуборд</b> оборудване</h1>
        <BaseButton noHover text={"разгледай"} onClick={() => window.scrollTo(50,560)}/>
      </div>
    </div>
  );
}