import { combineReducers } from 'redux';

import preloaderReducer from './preloaderReducer';
import priceRangeFilterReducer from './priceRangeFilterReducer';
import shoeTypeFilterReducer from './shoeTypeFilterReducer';
import sizesFilterReducer from './sizesFilterReducer';
import brandsFilterReducer from './brandsFilterReducer';
import cartProductsReducer from './cartProductsReducer';
import toastifyReducer from './toastifyReducer';

const rootReducer = combineReducers({
  preloader: preloaderReducer,
  priceRangeFilter: priceRangeFilterReducer,
  shoeTypeFilter: shoeTypeFilterReducer,
  sizesFilter: sizesFilterReducer,
  brandsFilter: brandsFilterReducer,
  cartProducts: cartProductsReducer,
  toastify: toastifyReducer
});

export default rootReducer;
