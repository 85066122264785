import React from "react";
import { Container, Row, Col } from 'reactstrap';
import imgSrc from '../../../../assets/images/error.webp';

export default function ProductNotFound ({ isSingleProduct, isCart, className }) {

    let text = '';

    if (isSingleProduct) {
        text = 'Продукта не е намерен';
    } else if (isCart) {
        text = 'Количката за пазаруване е празна';
    } else {
        text = 'Няма намерени продукти';
    }

    return (
        <Container className={`text-center ${className ? className : ''}`}>
           <Row>
                <Col>
                    <img src={imgSrc} alt="product-not-found" className="mb-3"/>
                    <h2>{text}</h2>
                </Col>
           </Row>
        </Container>
    )
}
