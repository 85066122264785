import React from 'react';
import PropTypes from 'prop-types'
import "./style.scss"

function DiscountPrice({ discountPrice, price, big, subText }) {

  return (
    <div className={`product-price ${big ? 'product-price-big' : ''}`}>
      {subText && <span className="sub-text">{subText}</span>}
      {discountPrice && <span className="price">{discountPrice}, 00 лв.</span>}
      {discountPrice !== price && <span className="discount-price">{price}, 00 лв.</span>}
  </div>
  );
}

DiscountPrice.propTypes = {
  discountPrice: PropTypes.number.isRequired,
  price: PropTypes.number.isRequired,
  big: PropTypes.bool,
  subText: PropTypes.string,
};

export default DiscountPrice;